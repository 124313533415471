<template>
  <div class="page">
    <div class="top-view flex-col" style="height: auto;">
      <div class="view-title flex-row align-center">
        <div class="border-left"></div>
        <span class="txt">标准定价：1元/秒，个人企业均能用</span>
      </div>
      <!-- <div class="view-desc">适合偶尔投放</div> -->
    </div>
    <div class="top-view flex-col">
      <div class="view-title flex-row align-center">
        <div class="border-left"></div>
        <div class="txt flex-row align-center">
          新人优惠：首单1元，个人企业均能用，
          <span
            style="color: #42b6fb; cursor: pointer"
            @click="goNewPeopleDiscounts"
            >了解更多</span
          >
          <img
            @click="goNewPeopleDiscounts"
            style="
              width: 20rem;
              height: 20rem;
              margin-left: 8rem;
              cursor: pointer;
            "
            src="@/assets/icon/arrow-right-circle.png"
            alt=""
          />
        </div>
      </div>
      <div class="view-desc">
        零成本体验大屏广告投放，<span @click="launch">去体验</span>
      </div>
    </div>
    <div class="business-view flex-col">
      <div class="view-title flex-row align-center">
        <div class="border-left"></div>
        <span class="txt">面向企业</span>
      </div>
      <div class="tab-view">
        <img
          v-if="buyType == 1"
          class="discount-bg"
          src="@/assets/img/discount-tab.png"
          alt=""
        />
        <img
          v-else
          class="common-bg"
          src="@/assets/img/common-tab.png"
          alt=""
        />
        <img class="js20" src="@/assets/img/js20.png" alt="" />
        <div class="discount-tab flex-col align-center" @click="tabClick(1)">
          <span :style="buyType == 1 ? 'color:#2BB3E3' : 'color: #252927;'"
            >按月付</span
          >
        </div>
        <div class="common-tab flex-col align-center" @click="tabClick(2)">
          <span :style="buyType == 2 ? 'color:#2BB3E3' : 'color: #252927;'"
            >按年付</span
          >
        </div>
      </div>
      <div class="monthPackage-view flex-col">
        <img
          class="enterprisePackageTips"
          src="@/assets/img/enterprisePackageTips.png"
          alt=""
        />
        <div
          v-if="buyType == 1"
          class="monthPackageList flex-row justify-between"
        >
          <div
            :class="{ 'list-item': true, 'disabled-item': item.disabled }"
            v-for="(item, index) in monthPackageList"
            :key="index"
          >
            <div
              v-if="fromPackageType == item.packageType"
              class="current-packageType"
            >
              您当前套餐
            </div>
            <div class="content flex-col align-center">
              <div class="top-title flex-row justify-center align-end">
                <span>{{ item.name }}</span>
                <span>/{{ item.desc1 }}</span>
              </div>
              <span class="price-txt">
                <span class="left-txt">￥</span>{{ item.price }}{{ item.desc6 }}
              </span>
              <div class="li flex-row justify-between align-center">
                <span>每月大屏广告时长</span>
                <span>{{ item.desc2 }}</span>
              </div>
              <div class="li flex-row justify-between align-center">
                <span>大屏广告时长平均单价</span>
                <span>{{ item.desc5 }}</span>
              </div>
              <!-- <div class="li flex-row justify-between align-center">
                <span>每月每屏幕前5000秒一折优惠</span>
                <img src="@/assets/icon/productPrice_dui.png" alt="" />
              </div>
              <div class="disabled-li flex-row justify-between align-center">
                <span>Canva可画高级版</span>
                <img src="@/assets/icon/productPrice_cuo.png" alt="" />
              </div>
              <div class="disabled-li flex-row justify-between align-center">
                <span>上门拍摄素材</span>
                <img src="@/assets/icon/productPrice_cuo.png" alt="" />
              </div>
              <div class="disabled-li flex-row justify-between align-center">
                <span>广告视频制作</span>
                <img src="@/assets/icon/productPrice_cuo.png" alt="" />
              </div> -->
              <div class="programme-view flex-col align-center">
                <span class="title">投放方案推荐</span>
                <span class="span1"
                  ><span style="font-weight: 500">长期投放：</span
                  >{{ item.desc3 }}</span
                >
                <span class="span2"
                  ><span style="font-weight: 500">集中投放：</span
                  >{{ item.desc4 }}</span
                >
                <div
                  v-if="item.packageType == 'm2'"
                  class="case flex-row align-center"
                >
                  <span>
                    精选案例：
                    <span
                      class="btn-txt"
                      @click="
                        openNewLabel(
                          'https://www.daping.cn/share?code=cd9ede31b47c49fa8263d8e1816c0da6'
                        )
                      "
                      >518平安保险节</span
                    >
                  </span>
                  <img
                    @click="
                      openNewLabel(
                        'https://www.daping.cn/share?code=cd9ede31b47c49fa8263d8e1816c0da6'
                      )
                    "
                    src="@/assets/icon/arrow-right-circle.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              class="pay-btn"
              id="primary-btn"
              @click="$throttle(buyVip, item)"
              v-if="!item.disabled"
            >
              购买
            </div>
            <div class="pay-btn" id="disable-btn" v-else>购买</div>
          </div>
          <div class="list-item">
            <div class="content flex-col justify-center align-center">
              <img class="productPrice-moreTimes"
                src="@/assets/img/productPrice_moreTimes.png" alt="" />
              <span class="txt">如需咨询或购买更多时长</span>
              <div class="align-center">
                <span class="txt">请</span>
                <txtServicePopover serviceTxt="联系我们" placement="right" />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="buyType == 2"
          class="yearPackageList flex-row justify-between"
        >
          <div
            :class="{ 'list-item': true, 'disabled-item': item.disabled }"
            v-for="(item, index) in yearPackageList"
            :key="index"
          >
            <div
              v-if="fromPackageType == item.packageType"
              class="current-packageType"
            >
              您当前套餐
            </div>
            <div v-if="item.diffPrice" class="diffPrice">
              需补差价{{ item.diffPrice }}元
            </div>
            <div class="content flex-col align-center">
              <div class="top-title flex-row justify-center align-end">
                <span>{{ item.name }}</span>
                <span>/{{ item.desc1 }}</span>
              </div>
              <span class="price-txt">
                <span class="left-txt">￥</span>{{ item.price }}{{ item.desc6 }}
              </span>
              <div class="li flex-row justify-between align-center">
                <span>每月大屏广告时长</span>
                <span>{{ item.desc2 }}</span>
              </div>
              <div class="li flex-row justify-between align-center">
                <span>大屏广告时长平均单价</span>
                <span>{{ item.desc5 }}</span>
              </div>
              <!-- <div class="li flex-row justify-between align-center">
                <span>每月每屏幕前5000秒一折优惠</span>
                <img src="@/assets/icon/productPrice_dui.png" alt="" />
              </div>
              <div
                v-if="item.packageType == 'y1'"
                class="disabled-li flex-row justify-between align-center"
              >
                <span>Canva可画高级版</span>
                <img src="@/assets/icon/productPrice_cuo.png" alt="" />
              </div>
              <div v-else class="li flex-row justify-between align-center">
                <span>Canva可画高级版</span>
                <img src="@/assets/icon/productPrice_dui.png" alt="" />
              </div>
              <div
                v-if="item.packageType == 'y1'"
                class="disabled-li flex-row justify-between align-center"
              >
                <span>上门拍摄素材</span>
                <img src="@/assets/icon/productPrice_cuo.png" alt="" />
              </div>
              <div v-else class="li flex-row justify-between align-center">
                <span>上门拍摄素材</span>
                <img src="@/assets/icon/productPrice_dui.png" alt="" />
              </div>
              <div
                v-if="item.packageType == 'y1'"
                class="disabled-li flex-row justify-between align-center"
              >
                <span>广告视频制作</span>
                <img src="@/assets/icon/productPrice_cuo.png" alt="" />
              </div>
              <div v-else class="li flex-row justify-between align-center">
                <span>广告视频制作</span>
                <img src="@/assets/icon/productPrice_dui.png" alt="" />
              </div> -->
              <div class="programme-view flex-col align-center">
                <span class="title">投放方案推荐</span>
                <span class="span1"
                  ><span style="font-weight: 500">长期投放：</span
                  >{{ item.desc3 }}</span
                >
                <span class="span2"
                  ><span style="font-weight: 500">集中投放：</span
                  >{{ item.desc4 }}</span
                >
                <div
                  v-if="item.packageType == 'y2'"
                  class="case flex-row align-center"
                >
                  <span>
                    精选案例：
                    <span
                      class="btn-txt"
                      @click="
                        openNewLabel(
                          'https://www.daping.cn/share?code=cd9ede31b47c49fa8263d8e1816c0da6'
                        )
                      "
                      >518平安保险节</span
                    >
                  </span>
                  <img
                    @click="
                      openNewLabel(
                        'https://www.daping.cn/share?code=cd9ede31b47c49fa8263d8e1816c0da6'
                      )
                    "
                    src="@/assets/icon/arrow-right-circle.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              class="pay-btn"
              id="primary-btn"
              @click="$throttle(buyVip, item)"
              v-if="!item.disabled"
            >
              购买
            </div>
            <div class="pay-btn" id="disable-btn" v-else>购买</div>
          </div>
          <div class="list-item">
            <div class="content flex-col justify-center align-center">
              <img class="productPrice-moreTimes"
                src="@/assets/img/productPrice_moreTimes.png" alt="" />
              <span class="txt">如需咨询或购买更多时长</span>
              <div class="align-center">
                <span class="txt">请</span>
                <txtServicePopover serviceTxt="联系我们" placement="right" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="y2Andy3-view flex-col">
        <div class="view-title flex-row align-center">
          <div class="border-left"></div>
          <span class="txt">套餐权益</span>
        </div>
        <div class="y2Andy3-item flex-row align-center">
          ·&nbsp;<span
            @click="goPayDuration"
            style="color: #42b6fb; cursor: pointer"
            >每月每屏幕前5000秒一折优惠（0.1元/秒，限前5000秒）</span
          >
        </div>
        <div class="y2Andy3-item flex-row align-center">
          ·
          <a href="https://www.canva.cn/pro/#pricing" target="_blank"
            >「Canva可画（高级版）」</a
          >在线视频制作功能（含10万+模板），价值300元/年
          <el-popover
            class="popover"
            placement="right"
            trigger="hover"
            :visible-arrow="false"
            popper-class="canvaPopover"
          >
            <div class="popover-content flex-col justify-between">
              <span
                >您无需单独注册「Canva可画」，相关功能已被集成进我们的网站中。</span
              >
            </div>
            <div slot="reference" class="reference flex-row align-center">
              <svg-icon class="service-svg" iconClass="overlay"></svg-icon>
            </div>
          </el-popover>
        </div>
        <div class="y2Andy3-item flex-row align-center">
          · 上门拍摄素材一次（限肇庆及深圳本地商家），价值300元/次
          <el-popover
            class="popover"
            placement="right"
            trigger="hover"
            :visible-arrow="false"
            popper-class="canvaPopover"
          >
            <div class="popover-content flex-col justify-between">
              <span
                >我们已为以下肇庆商家提供过上内拍摄广告素材服务：广东冠略律师事务所、名门月府月子中心、斯托凡诺、苹果男装、燕茸堂等。</span
              >
            </div>
            <div slot="reference" class="reference flex-row align-center">
              <svg-icon class="service-svg" iconClass="overlay"></svg-icon>
            </div>
          </el-popover>
        </div>
        <div class="y2Andy3-item flex-row align-center">
          · 广告视频制作一次（不限地域），价值450元/次
          <el-popover
            class="popover"
            placement="right"
            trigger="hover"
            :visible-arrow="false"
            popper-class="canvaPopover"
          >
            <div class="popover-content flex-col justify-between">
              <span>您还可以选择普通话或粤语配音，真人配音！</span>
            </div>
            <div slot="reference" class="reference flex-row align-center">
              <svg-icon class="service-svg" iconClass="overlay"></svg-icon>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="individual-view flex-col">
      <div class="view-title flex-row align-center">
        <div class="border-left"></div>
        <span class="txt">面向个人</span>
      </div>
      <div class="package-view flex-col">
        <div class="packageList flex-row justify-between">
          <div
            class="list-item flex-col justify-between"
            v-for="(item, index) in individualPackageList"
            :key="index"
          >
            <div class="second-view flex-row justify-center align-center">
              <img src="@/assets/img/stopwatch.png" alt="" />
              <span>{{ item.desc1 }}</span>
            </div>
            <span class="desc">{{ item.desc2 }}</span>
            <span class="desc">{{ item.desc3 }}</span>
            <div class="price-view">
              <span>￥{{ item.price }}</span>
            </div>
            <div
              class="pay-btn"
              id="primary-btn"
              @click="$throttle(buyPackage, item)"
            >
              购买
            </div>
          </div>
        </div>
        <div class="optimize1 flex-row align-center">
          可用于公开表白、送祝福等，仅个人用途，不可用于商业广告
        </div>
      </div>
      <div class="case-view flex-col">
        <div class="view-title flex-row align-center">
          <div class="border-left"></div>
          <span class="txt">精选案例</span>
        </div>
        <div
          class="case-item flex-row align-center"
          @click="
            openNewLabel(
              'https://www.daping.cn/share?code=62b3b23a53f4483d8954f45e9cb5418f'
            )
          "
        >
          <span class="span1">·&nbsp;</span>
          <span>为偶像打Call</span>
          <img src="@/assets/icon/arrow-right-circle.png" alt="" />
        </div>
        <div
          class="case-item flex-row align-center"
          @click="
            openNewLabel(
              'https://www.daping.cn/share?code=246a0d0d41954f84807290cb5a46d054'
            )
          "
        >
          <span class="span1">·&nbsp;</span>
          <span>公开表白</span>
          <img src="@/assets/icon/arrow-right-circle.png" alt="" />
        </div>
        <div
          class="case-item flex-row align-center"
          @click="
            openNewLabel(
              'https://www.daping.cn/share?code=ab2a11658bdb428184123ef529edb50d'
            )
          "
        >
          <span class="span1">·&nbsp;</span>
          <span>周年纪念</span>
          <img src="@/assets/icon/arrow-right-circle.png" alt="" />
        </div>
        <div
          class="case-item flex-row align-center"
          @click="
            openNewLabel(
              'https://www.daping.cn/share?code=236255a205e945099858b9d36c384e08'
            )
          "
        >
          <span class="span1">·&nbsp;</span>
          <span>生日祝福</span>
          <img src="@/assets/icon/arrow-right-circle.png" alt="" />
        </div>
        <div
          class="case-item flex-row align-center"
          @click="
            openNewLabel(
              'https://www.daping.cn/share?code=d240a37b33ac43c38a5da34daeb170c3'
            )
          "
        >
          <span class="span1">·&nbsp;</span>
          <span>晒娃</span>
          <img src="@/assets/icon/arrow-right-circle.png" alt="" />
        </div>
      </div>
    </div>
    <div ref="scrollDiv" class="faq-view">
      <div class="view-title flex-row align-center">
        <div class="border-left"></div>
        <span class="txt">常见问题</span>
      </div>
      <el-collapse v-model="activeName" accordion class="collapse-view">
        <el-collapse-item title="" name="1">
          <template slot="title">
            <img
              v-if="activeName == 1"
              class="plus_icon"
              src="@/assets/icon/plus_icon.png"
              alt=""
            />
            <img
              v-else
              class="minus_icon"
              src="@/assets/icon/minus_icon.png"
              alt=""
            />
            企业套餐每月每屏幕前5000秒一折是指什么？
          </template>
          <div>套餐有效期内，您可以一折购买时长。</div>
          <div>针对每个目标广告屏，您最多可按此优惠购买5000秒/月。</div>
          <div>一折购买的时长，自购买之日起1个月有效。</div>
          <div class="flex-row align-center">
            如果5000秒仍不够用，您可以<txtServicePopover
              serviceTxt="联系我们"
              placement="right"
            />。
          </div>
        </el-collapse-item>
        <el-collapse-item title="" name="2">
          <template slot="title">
            <img
              v-if="activeName == 2"
              class="plus_icon"
              src="@/assets/icon/plus_icon.png"
              alt=""
            />
            <img
              v-else
              class="minus_icon"
              src="@/assets/icon/minus_icon.png"
              alt=""
            />
            企业套餐的时长是否有有效期？
          </template>
          <div>每月到账的时长有效期为一个月。</div>
          <div>举例：3月6日到账的，需在4月5日23:59:59前使用完。</div>
        </el-collapse-item>
        <el-collapse-item title="" name="3">
          <template slot="title">
            <img
              v-if="activeName == 3"
              class="plus_icon"
              src="@/assets/icon/plus_icon.png"
              alt=""
            />
            <img
              v-else
              class="minus_icon"
              src="@/assets/icon/minus_icon.png"
              alt=""
            />
            企业套餐的时长每月几号到账？
          </template>
          <div>按月付费的，立即到账。</div>
          <div>
            按年付费的，第1个月的立即到账，后续11个月的于每月相同日期到账。若某月无该日期，则于当月最后一日到账。
          </div>
          <div>每次到账时，您都会收到短信通知。</div>
          <div>
            举例：1月30日您选择按年付购买基础版套餐，购买成功后立即到账1000秒，以后每月30日自动到账1000秒，其中2月的到账日期为2月28日或者2月29日。
          </div>
        </el-collapse-item>
        <el-collapse-item title="" name="4">
          <template slot="title">
            <img
              v-if="activeName == 4"
              class="plus_icon"
              src="@/assets/icon/plus_icon.png"
              alt=""
            />
            <img
              v-else
              class="minus_icon"
              src="@/assets/icon/minus_icon.png"
              alt=""
            />
            企业套餐之间可以切换吗？
          </template>
          <div>
            按月付费的，只能从低版本切换到高版本，或者从按月付费切换为按年付费。
          </div>
          <div>
            按年付费的，可以从低版本切换到高版本，也可以从高版本切换到低版本，前者补差价，后者退差价。
          </div>
        </el-collapse-item>
        <el-collapse-item title="" name="5">
          <template slot="title">
            <img
              v-if="activeName == 5"
              class="plus_icon"
              src="@/assets/icon/plus_icon.png"
              alt=""
            />
            <img
              v-else
              class="minus_icon"
              src="@/assets/icon/minus_icon.png"
              alt=""
            />
            企业套餐“按月付”一个月内可以购买多次吗？
          </template>
          <div>不能购买相同套餐或低版本“按月付”套餐。</div>
          <div>
            但可以升级到高版本的“按月付”套餐，也可以升级成任意版本的“按年付”套餐。
          </div>
        </el-collapse-item>
        <el-collapse-item title="" name="6">
          <template slot="title">
            <img
              v-if="activeName == 6"
              class="plus_icon"
              src="@/assets/icon/plus_icon.png"
              alt=""
            />
            <img
              v-else
              class="minus_icon"
              src="@/assets/icon/minus_icon.png"
              alt=""
            />
            企业套餐不再使用了，可以退款吗？
          </template>
          <div>按月付的套餐，不可以退款，因为时长是支付后立即到账。</div>
          <div>按年付的套餐，已到账的时长不退款，未到账的时长可退款。</div>
        </el-collapse-item>
        <el-collapse-item title="" name="7">
          <template slot="title">
            <img
              v-if="activeName == 7"
              class="plus_icon"
              src="@/assets/icon/plus_icon.png"
              alt=""
            />
            <img
              v-else
              class="minus_icon"
              src="@/assets/icon/minus_icon.png"
              alt=""
            />
            企业套餐可以开发票吗？
          </template>
          <div class="flex-row align-center">
            可以，请<txtServicePopover
              serviceTxt="联系我们"
              placement="right"
            />。
          </div>
        </el-collapse-item>
        <el-collapse-item title="" name="8">
          <template slot="title">
            <img
              v-if="activeName == 8"
              class="plus_icon"
              src="@/assets/icon/plus_icon.png"
              alt=""
            />
            <img
              v-else
              class="minus_icon"
              src="@/assets/icon/minus_icon.png"
              alt=""
            />
            企业套餐能提供合同吗？
          </template>
          <div class="flex-row align-center">
            可以，请<txtServicePopover
              serviceTxt="联系我们"
              placement="right"
            />。
          </div>
        </el-collapse-item>
        <el-collapse-item title="" name="9">
          <template slot="title">
            <img
              v-if="activeName == 9"
              class="plus_icon"
              src="@/assets/icon/plus_icon.png"
              alt=""
            />
            <img
              v-else
              class="minus_icon"
              src="@/assets/icon/minus_icon.png"
              alt=""
            />
            支持哪些方式付款？
          </template>
          <div>微信支付、支付宝、企业公对公转账</div>
          <div class="flex-row align-center">
            公对公转账需<txtServicePopover
              serviceTxt="联系我们"
              placement="right"
            />。
          </div>
        </el-collapse-item>
        <el-collapse-item title="" name="10">
          <template slot="title">
            <img
              v-if="activeName == 10"
              class="plus_icon"
              src="@/assets/icon/plus_icon.png"
              alt=""
            />
            <img
              v-else
              class="minus_icon"
              src="@/assets/icon/minus_icon.png"
              alt=""
            />
            企业套餐可否用于个人表白、送祝福？
          </template>
          <div>不可以。</div>
        </el-collapse-item>
        <el-collapse-item title="" name="11">
          <template slot="title">
            <img
              v-if="activeName == 11"
              class="plus_icon"
              src="@/assets/icon/plus_icon.png"
              alt=""
            />
            <img
              v-else
              class="minus_icon"
              src="@/assets/icon/minus_icon.png"
              alt=""
            />
            慈善机构或其它非赢利组织有优惠吗？
          </template>
          <div class="flex-row align-center">
            请<txtServicePopover serviceTxt="联系我们" placement="right" />。
          </div>
        </el-collapse-item>
        <el-collapse-item title="" name="12">
          <template slot="title">
            <img
              v-if="activeName == 12"
              class="plus_icon"
              src="@/assets/icon/plus_icon.png"
              alt=""
            />
            <img
              v-else
              class="minus_icon"
              src="@/assets/icon/minus_icon.png"
              alt=""
            />
            个人表白/送祝福套餐是否有有效期？
          </template>
          <div>有效期365天。可分多次使用。</div>
        </el-collapse-item>
        <el-collapse-item title="" name="13">
          <template slot="title">
            <img
              v-if="activeName == 13"
              class="plus_icon"
              src="@/assets/icon/plus_icon.png"
              alt=""
            />
            <img
              v-else
              class="minus_icon"
              src="@/assets/icon/minus_icon.png"
              alt=""
            />
            个人表白/送祝福套餐是否可退款？
          </template>
          <div>不可以退款，因为时长是支付后立即到账。</div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <pay-type ref="refPayType" :propsData="payTypeData"></pay-type>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { getUserInfo } from "@/api/request";
import txtServicePopover from "@/components/txtServicePopover.vue";
import payType from "@/components/proPricepayTypeDialog.vue";

export default {
  components: {
    txtServicePopover,
    payType,
  },
  data() {
    return {
      buyType: 1,
      isLogin: window.localStorage.getItem("token"),
      activeName: "",
      fromPackageType: "", // 自费会员套餐情况，即使不是自费会员，也有上一次购买的记录
      payTypeData: {}, // 支付弹窗数据
      monthPackageList: [
        {
          packageType: "m1",
          name: "基础版",
          desc1: "适合本地小商户",
          desc2: "1000秒",
          desc3:
            "15秒广告视频，每天投放2次，上下班高峰期各1次。持续唤起用户记忆。适合长期宣传、巩固品牌印象。",
          desc4:
            "15秒广告视频，连续投放66次，每5分钟1次，可连续投放5.5小时。适合新店开张、店庆促销等活动宣传。",
          desc5: "0.80元/秒",
          desc6: "元/月",
          price: 800,
          disabled: false,
        },
        {
          packageType: "m2",
          name: "进取版",
          desc1: "适合创业企业",
          desc2: "3000秒",
          desc3:
            "15秒广告视频，每天投放6次，上下班高峰期各3次。投放频次较高，且持续投放，能让更多人记住您的品牌。",
          desc4:
            "15秒广告视频，每5分钟1次，一天投放100次，可连续投放2天。适合新店开张、店庆促销等活动的密集宣传。",
          desc5: "0.75元/秒",
          desc6: "元/月",
          price: 2250,
          disabled: false,
        }
      ],
      yearPackageList: [
        {
          packageType: "y1",
          name: "基础版",
          desc1: "适合本地小商户",
          desc2: "1000秒",
          desc3:
            "15秒广告视频，每天投放2次，上下班高峰期各1次。持续唤起用户记忆。适合长期宣传、巩固品牌印象。",
          desc4:
            "15秒广告视频，连续投放66次，每5分钟1次，可连续投放5.5小时。适合新店开张、店庆促销等活动宣传。",
          desc5: "0.64元/秒",
          desc6: "元/年",
          price: 7680,
          disabled: false,
        },
        {
          packageType: "y2",
          name: "进取版",
          desc1: "适合创业企业",
          desc2: "3000秒",
          desc3:
            "15秒广告视频，每天投放6次，上下班高峰期各3次。投放频次较高，且持续投放，能让更多人记住您的品牌。",
          desc4:
            "15秒广告视频，每5分钟1次，一天投放100次，可连续投放2天。适合新店开张、店庆促销等活动的密集宣传。",
          desc5: "0.60元/秒",
          desc6: "元/年",
          price: 21600,
          disabled: false,
        }
      ],
      individualPackageList: [
        {
          packageStyle: 5,
          desc1: "520秒",
          desc2: "· 累计霸屏9分钟",
          desc3: "· 平均0.76元/秒",
          price: 399,
        },
        {
          packageStyle: 6,
          desc1: "1314秒",
          desc2: "· 累计霸屏22分钟",
          desc3: "· 平均0.6元/秒",
          price: 799,
        },
        {
          packageStyle: 7,
          desc1: "5200秒",
          desc2: "· 累计霸屏1.5小时",
          desc3: "· 平均0.38元/秒",
          price: 1999,
        },
      ],
    };
  },
  computed: {
    isBuyVip() {
      return this.$store.state.userInfo.businessInfo.isBuyVip;
    }
  },
  created() {
    if (this.isLogin) {
      this.getUserInfo();
    }
  },
  mounted() {
    if (this.$route.query.active) {
      this.buyType = this.$route.query.active;
    }
  },
  methods: {
    // 查询用户信息
    getUserInfo() {
      return new Promise((resolve, reject) => {
        getUserInfo({})
          .then((res) => {
            this.$store.dispatch("setUserInfoActions", res.userInfo);
            const buyVipInfos = res.userInfo.businessInfo.buyVipInfo;
            if (res.userInfo.businessInfo.isBuyVip && buyVipInfos) {
              this.fromPackageType = buyVipInfos.packageType;
            } else {
              this.fromPackageType = "";
            }
            // 如果是自费黄金会员，则判断哪些套餐可购买；如果不是自费黄金会员，所有套餐均可购买
            if (res.userInfo.businessInfo.isBuyVip && buyVipInfos) {
              switch (buyVipInfos.packageType) {
                case "m1":
                  this.monthPackageList.forEach((item, index) => {
                    if (index == 0) {
                      item.disabled = true;
                    } else {
                      item.disabled = false;
                    }
                  });
                  this.yearPackageList.forEach((item) => {
                    item.disabled = false;
                  });
                  break;
                case "m2":
                  this.monthPackageList.forEach((item, index) => {
                    if (index <= 1) {
                      item.disabled = true;
                    } else {
                      item.disabled = false;
                    }
                  });
                  this.yearPackageList.forEach((item) => {
                    item.disabled = false;
                  });
                  break;
                case "m3":
                  this.monthPackageList.forEach((item) => {
                    item.disabled = true;
                  });
                  this.yearPackageList.forEach((item) => {
                    item.disabled = false;
                  });
                  break;
                case "y1":
                  this.monthPackageList.forEach((item) => {
                    item.disabled = true;
                  });
                  this.yearPackageList.forEach((item, index) => {
                    if (index == 0) {
                      item.disabled = true;
                    } else {
                      item.disabled = false;
                    }
                  });
                  this.yearPackageList[1].diffPrice = Math.floor(
                    this.yearPackageList[1].price -
                      (this.yearPackageList[0].price *
                        (buyVipInfos.buyVipTimes -
                          buyVipInfos.buyVipValidTimes)) /
                        12
                  );
                  // this.yearPackageList[2].diffPrice = Math.floor(
                  //   this.yearPackageList[2].price -
                  //     (this.yearPackageList[0].price *
                  //       (buyVipInfos.buyVipTimes -
                  //         buyVipInfos.buyVipValidTimes)) /
                  //       12
                  // );
                  break;
                case "y2":
                  this.monthPackageList.forEach((item) => {
                    item.disabled = true;
                  });
                  this.yearPackageList.forEach((item, index) => {
                    if (index <= 1) {
                      item.disabled = true;
                    } else {
                      item.disabled = false;
                    }
                  });
                  // this.yearPackageList[2].diffPrice = Math.floor(
                  //   this.yearPackageList[2].price -
                  //     (this.yearPackageList[1].price *
                  //       (buyVipInfos.buyVipTimes -
                  //         buyVipInfos.buyVipValidTimes)) /
                  //       12
                  // );
                  break;
                case "y3":
                  this.monthPackageList.forEach((item) => {
                    item.disabled = true;
                  });
                  this.yearPackageList.forEach((item) => {
                    item.disabled = true;
                  });
                  break;

                default:
                  break;
              }
            } else {
              this.monthPackageList.forEach((item) => {
                item.disabled = false;
              });
              this.yearPackageList.forEach((item) => {
                item.disabled = false;
              });
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 面向企业（企业套餐）支付
    buyVip(item) {
      // 如果需要补差价
      if (item.diffPrice && item.diffPrice > 0) {
        this.payTypeData = {
          fromType: "buyVip",
          payAmount: item.diffPrice,
          style: item.packageType,
        };
      } else {
        this.payTypeData = {
          fromType: "buyVip",
          payAmount: item.price,
          style: item.packageType,
        };
      }
      setTimeout(() => {
        this.$refs.refPayType.openPayType();
      }, 100);
    },
    // 面向个人（表白送祝福套餐）支付
    buyPackage(item) {
      this.payTypeData = {
        fromType: "buyPackage",
        payAmount: item.price,
        style: item.packageStyle,
      };
      setTimeout(() => {
        this.$refs.refPayType.openPayType();
      }, 100);
    },
    launch() {
      if (this.isLogin) {
        this.$canvaUtil.onClickCreate();
      } else {
        this.$router.push("/login").catch(() => {});
      }
    },
    goNewPeopleDiscounts() {
      this.$router.push("/others/newPeopleDiscounts");
    },
    openActiveName1() {
      this.activeName = "1";
      this.$nextTick(() => {
        const scrollElem = this.$refs.scrollDiv;
        window.scrollTo({
          top: scrollElem.offsetTop - 100,
          behavior: "smooth",
        });
      });
    },
    goPayDuration() {
      if (this.isBuyVip) {
        this.$router.push({ name: "PayDuration" });
      } else {
        this.openActiveName1();
      }
    },
    tabClick(index) {
      this.buyType = index;
      if (this.isLogin) {
        this.getUserInfo();
      }
    },
    openNewLabel(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.popover {
  margin-left: 3rem;
  .popover-content {
    span {
      font-size: 14rem;
      color: #252927;
      line-height: 20rem;
    }
  }
  .reference {
    cursor: pointer;
    .service-svg {
      color: #696969;
      width: 20rem;
      height: 20rem;
    }
    &:hover {
      .service-svg {
        color: #32c3f6;
      }
    }
  }
}
.view-title {
  .border-left {
    width: 3rem;
    height: 18rem;
    background: linear-gradient(225deg, #4facfe 0%, #0ae1ec 100%);
    margin-right: 14rem;
  }
  .txt {
    font-size: 18rem;
    color: #252927;
    line-height: 25rem;
  }
}
.page {
  width: 1280rem;
  padding: 48rem 0 100rem;
  margin: 0 auto;
  background-color: #f8f8f8;
  min-height: calc(100vh - 233rem);
  .top-view {
    width: 100%;
    height: 151rem;
    background: #ffffff;
    border-radius: 6rem;
    padding: 40rem 48rem;
    margin-bottom: 40rem;
    .view-desc {
      font-size: 16rem;
      color: #949694;
      line-height: 22rem;
      margin-top: 24rem;
      span {
        color: #42b6fb;
        line-height: 22rem;
        text-decoration: underline;
        cursor: pointer;
        // &:hover {
        //   color: #059ed6;
        // }
      }
    }
  }
  .business-view {
    width: 100%;
    background: #ffffff;
    border-radius: 6rem;
    padding: 43rem 43rem 33rem 53rem;
    .tab-view {
      width: 1060rem;
      position: relative;
      left: 1rem;
      margin-top: 23rem;
      .discount-bg,
      .common-bg {
        width: 100%;
      }
      .js20 {
        width: 136rem;
        height: 32rem;
        position: absolute;
        top: -16rem;
        right: 454rem;
      }
      .discount-tab,
      .common-tab {
        width: 274rem;
        height: 100%;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        span {
          &:nth-child(1) {
            font-size: 22rem;
            line-height: 30rem;
            margin-top: 21rem;
          }
          &:nth-child(2) {
            font-size: 16rem;
            color: #a5a8a9;
            line-height: 22rem;
            margin-top: 2rem;
          }
        }
      }
      .common-tab {
        left: 290rem;
      }
    }
    .monthPackage-view {
      width: 100%;
      height: 619rem;
      position: relative;
      background: url("../../assets/img/payOptions_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      bottom: 15rem;
      padding: 30rem 30rem 20rem;
      &:hover {
        .enterprisePackageTips {
          display: block;
        }
      }
      .enterprisePackageTips {
        width: 272rem;
        height: 95rem;
        position: absolute;
        top: -106rem;
        right: 0;
        display: none;
      }
      .monthPackageList,
      .yearPackageList {
        width: 100%;
        .list-item {
          width: 354rem;
          height: 495rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background: #ffffff;
          border-radius: 12rem;
          position: relative;
          .current-packageType {
            background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
            border-radius: 2rem 12rem 2rem 2rem;
            padding: 2rem 5rem;
            font-size: 14rem;
            color: #ffffff;
            line-height: 20rem;
            position: absolute;
            top: 0;
            right: 0;
          }
          .diffPrice {
            background: #2ed3e7;
            border-radius: 2rem 12rem 2rem 2rem;
            padding: 2rem 5rem;
            font-size: 14rem;
            color: #ffffff;
            line-height: 20rem;
            position: absolute;
            top: 0;
            right: 0;
          }
          .content {
            width: 100%;
            height: 100%;
            padding: 25rem 20rem;
            .productPrice-moreTimes {
              width: 169rem;
              height: 177rem;
              margin-bottom: 25rem;
            }
            .top-title {
              width: 100%;
              span {
                &:nth-child(1) {
                  font-size: 20rem;
                  font-weight: 500;
                  color: #252927;
                  line-height: 28rem;
                }
                &:nth-child(2) {
                  font-size: 15rem;
                  font-weight: 400;
                  color: #7c7e7d;
                  line-height: 21rem;
                  margin-left: 5rem;
                }
              }
            }
            .price-txt {
              font-size: 20rem;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #2bb3e3;
              line-height: 22rem;
              margin-top: 20rem;
              margin-bottom: 5rem;
              .left-txt {
                font-size: 16rem;
              }
            }
            .li,
            .disabled-li {
              width: 100%;
              margin-top: 15rem;
              span {
                font-size: 18rem;
                color: #252927;
                line-height: 25rem;
              }
              img {
                width: 24rem;
                height: 24rem;
              }
            }
            .disabled-li {
              span {
                color: rgba(0, 0, 0, 0.45);
              }
            }
            .programme-view {
              width: 100%;
              border-top: 1px solid #e5e5e5;
              margin-top: 21rem;
              padding-top: 15rem;
              .title {
                font-size: 18rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #252927;
                line-height: 25rem;
                margin-bottom: 15rem;
              }
              .span1,
              .span2 {
                font-size: 16rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 400;
                color: #252927;
                line-height: 22rem;
              }
              .span2 {
                margin-top: 10rem;
              }
              .case {
                width: 100%;
                margin-top: 10rem;
                span {
                  font-size: 16rem;
                  font-family: PingFangSC-Medium, PingFang SC;
                  font-weight: 500;
                  color: #252927;
                  line-height: 22rem;
                  .btn-txt {
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #42b6fb;
                    cursor: pointer;
                  }
                }
                img {
                  width: 20rem;
                  height: 20rem;
                  margin-left: 8rem;
                  cursor: pointer;
                }
              }
            }
          }
          .pay-btn {
            position: absolute;
            bottom: -58rem;
            left: 106rem;
            width: 112rem;
            height: 38rem;
            text-align: center;
            line-height: 38rem;
            font-size: 16rem;
            color: #fff;
            border-radius: 10rem;
          }
        }
        .disabled-item {
          .content {
            opacity: 0.7;
            .top-title {
              span {
                &:nth-child(1) {
                  color: #929492;
                }
                &:nth-child(2) {
                  color: #929493;
                }
              }
            }
            .price-txt {
              color: rgba(37, 41, 39, 0.6);
            }
          }
        }
      }
      .optimize1 {
        font-size: 16rem;
        color: #a4a8a9;
        line-height: 22rem;
        position: absolute;
        left: 56rem;
        bottom: 20rem;
        span {
          color: #42b6fb;
          line-height: 22rem;
          text-decoration: underline;
          cursor: pointer;
          // &:hover {
          //   color: #059ed6;
          // }
        }
      }
    }
    .y2Andy3-view {
      .view-title {
        margin: 26rem 0 9rem 0;
      }
      .y2Andy3-item {
        font-size: 18rem;
        color: #252927;
        line-height: 25rem;
        margin-top: 15rem;
        margin-left: 17rem;
        a {
          color: #42b6fb;
          text-decoration: none;
          // &:hover {
          //   color: #059ed6;
          // }
        }
      }
    }
  }
  .individual-view {
    width: 100%;
    background: #ffffff;
    border-radius: 6rem;
    padding: 40rem 48rem;
    margin-top: 47rem;
    .package-view {
      width: 100%;
      height: 374rem;
      position: relative;
      border-radius: 12rem;
      background: url("../../assets/img/payOptions_bg.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 55rem 56rem 20rem;
      margin-top: 24rem;
      .packageList {
        width: 100%;
        .list-item {
          width: 322rem;
          height: 193rem;
          background: #ffffff;
          border-radius: 12rem;
          padding-top: 21rem;
          position: relative;
          .second-view {
            img {
              width: 18rem;
              height: 20rem;
              margin-right: 8rem;
            }
            span {
              font-size: 18rem;
              color: #252927;
              line-height: 25rem;
            }
          }
          .desc {
            font-size: 18rem;
            color: #252927;
            line-height: 25rem;
            margin-left: 92rem;
          }
          .price-view {
            width: 100%;
            height: 44rem;
            text-align: center;
            line-height: 44rem;
            background: #daf5ff;
            font-size: 20rem;
            font-weight: 500;
            color: #2bb3e3;
            border-radius: 0px 0px 12rem 12rem;
          }
          .pay-btn {
            position: absolute;
            bottom: -58rem;
            left: 106rem;
            width: 112rem;
            height: 38rem;
            text-align: center;
            line-height: 38rem;
            font-size: 16rem;
            color: #fff;
            border-radius: 10rem;
          }
        }
      }
      .optimize1 {
        font-size: 16rem;
        color: #a4a8a9;
        line-height: 22rem;
        position: absolute;
        left: 56rem;
        bottom: 20rem;
      }
    }
    .case-view {
      .view-title {
        margin: 26rem 0 9rem 0;
      }
      .case-item {
        margin-top: 15rem;
        margin-left: 17rem;
        cursor: pointer;
        span {
          font-size: 18rem;
          color: #2bb3e3;
          line-height: 25rem;
        }
        .span1 {
          color: #252927;
        }
        img {
          width: 20rem;
          height: 20rem;
          margin-left: 8rem;
        }
      }
    }
  }
  .faq-view {
    width: 100%;
    background: #ffffff;
    border-radius: 6rem;
    padding: 40rem 48rem;
    margin-top: 40rem;
    .collapse-view {
      margin-top: 12rem;
    }
  }
}

::v-deep .el-collapse-item__wrap {
  border-bottom: none;
}
::v-deep .el-collapse-item__header {
  font-size: 16rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #252927;
  line-height: 25rem;
  border-bottom: none;
  .minus_icon {
    width: 16rem;
    height: 16rem;
    margin-right: 10rem;
  }
  .plus_icon {
    width: 16rem;
    height: 4rem;
    margin-right: 10rem;
  }
}
::v-deep .el-collapse {
  border: none;
}
::v-deep .el-collapse-item__content {
  font-size: 15rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #7d817f;
  line-height: 32rem;
  padding-bottom: 0;
  padding-left: 26rem;
}
::v-deep .el-collapse-item__arrow {
  display: none;
}
</style>
