<template>
  <el-dialog
    title=""
    :visible.sync="payTypeStatus"
    width="557rem"
    class="dialog"
  >
    <div class="dialog_box flex-col">
      <div class="flex-row align-center">
        <div class="tips-titlt">支付金额：</div>
        <div class="tips-payAmount flex-row align-center">
          ￥<span>{{ payDialogData.payAmount }}</span>
        </div>
      </div>
      <div
        class="flex-row align-center"
        style="margin-top: 16rem; position: relative"
      >
        <div class="tips-titlt">支付方式：</div>
        <el-radio-group
          class="payType flex-row justify-center"
          v-model="payType"
        >
          <el-radio label="1" border :disabled="isOverstep"
            ><img src="@/assets/icon/weixin.png" alt="" /><span
              >微信支付</span
            ></el-radio
          >
          <el-radio label="4" border
            ><img src="@/assets/icon/zhifubao.png" alt="" /><span
              >支付宝</span
            ></el-radio
          >
        </el-radio-group>
        <div v-if="isOverstep" class="overstep flex-row align-center">
          <img src="@/assets/icon/pay_tanhao.png" alt="" />
          <span>超出微信支付虚拟商品日付款额度，该套餐只支持支付宝付款</span>
        </div>
      </div>
      <div slot="footer" class="dialog-footer flex-row justify-center">
        <div v-if="!submitStatus" id="primary-btn" @click="pay">立即支付</div>
        <div v-else id="primary-btn">
          <img src="@/assets/img/loading.gif" width="60" height="60" />
        </div>
      </div>
      <div class="service flex-row justify-center align-center">
        更多支付方式（如企业网银转账），请
        <txtServicePopover />
      </div>
    </div>
  </el-dialog>
</template>

<script>
/* eslint-disable vue/require-valid-default-prop */
import { buyVip, createPackage, getUserInfo } from "@/api/request";
import txtServicePopover from "./txtServicePopover.vue";

export default {
  components: {
    txtServicePopover,
  },
  props: {
    propsData: {
      type: Object,
      default: {},
      required: true,
    },
  },
  data() {
    return {
      isLogin: window.localStorage.getItem("token"),
      payType: "1", // 默认微信支付
      payTypeStatus: false,
      submitStatus: false,
      isOverstep: false, // 支付金额是否超过5000元
      fromPackageType: "", // 自费会员套餐情况，即使不是自费会员，也有上一次购买的记录
      payDialogData: {
        fromType: null,
        payAmount: 0,
        style: null
      },
    };
  },
  created() {},
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  methods: {
    // 打开弹窗
    openPayType() {
      console.log(this.propsData);
      this.payDialogData = { ...this.propsData };
      if (this.payDialogData.payAmount > 5000) {
        this.isOverstep = true;
        this.payType = "4";
      } else {
        this.isOverstep = false;
        this.payType = "1";
      }
      this.payTypeStatus = true;
    },
    // 点击确定支付
    pay() {
      if (!this.payType) {
        this.$message({
          message: "请选择支付方式",
          type: "warning",
          duration: 3000,
          center: true,
        });
        return;
      }
      if (this.payDialogData.fromType == 'buyVip') {
        this.createBuyVip();
      }
      if (this.payDialogData.fromType == 'buyPackage') {
        this.createBuyPackage();
      }
    },
    // 面向企业（企业套餐）支付
    createBuyVip() {
      if (!this.isLogin) {
        this.$router.push("/login").catch(() => {});
        return;
      }
      this.submitStatus = true;
      this.queryUserInfo().then(() => {
        const params = {
          pay_type: this.payType,
          is_mobile: false,
          is_wx_js: false,
          from_package_type: this.fromPackageType,
          package_type: this.payDialogData.style,
          order_expire_minute: 10,
        };
        buyVip(params).then((res) => {
          this.submitStatus = false;
          // 微信支付，直接跳转支付状态查询页
          this.$router.push({
            name: "BuyVipPayStatus",
            query: {
              buyVipOrderId: res.buyVipOrderId,
            },
          });
        })
          .catch(() => {
            this.submitStatus = false;
          });
      }).catch(() => {
        this.submitStatus = false;
      });
    },
    // 面向个人（表白送祝福套餐）支付
    createBuyPackage() {
      if (!this.isLogin) {
        this.$router.push("/login").catch(() => {});
        return;
      }
      this.submitStatus = true;
      const params = {
        pay_type: this.payType,
        is_mobile: false,
        is_wx_js: false,
        package_style: this.payDialogData.style,
        order_expire_minute: 10,
      };
      createPackage(params).then((res) => {
        this.submitStatus = false;
        // 微信支付，直接跳转支付状态查询页
        this.$router.push({
          name: "BuyPackagePayStatus",
          query: {
            tempOrderId: res.tempOrderId,
          },
        });
      })
        .catch(() => {
          this.submitStatus = false;
        });
    },
    // 查询用户信息
    queryUserInfo() {
      return new Promise((resolve, reject) => {
        getUserInfo({})
          .then((res) => {
            this.$store.dispatch("setUserInfoActions", res.userInfo);
            const buyVipInfos = res.userInfo.businessInfo.buyVipInfo;
            if (res.userInfo.businessInfo.isBuyVip && buyVipInfos) {
              this.fromPackageType = buyVipInfos.packageType;
            } else {
              this.fromPackageType = "";
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .el-icon-close {
    position: absolute;
    top: -95%;
    right: -79%;
    color: #fff;
    font-size: 36rem;
    cursor: pointer;
  }
  .dialog_box {
    .overstep {
      position: absolute;
      left: 0;
      bottom: -30rem;
      img {
        width: 20rem;
        height: 20rem;
      }
      span {
        color: #F52F3E;
        font-size: 14rem;
        margin-left: 6rem;
      }
    }
    .tips-titlt {
      height: 18rem;
      line-height: 18rem;
      border-left: 3rem solid #3bbcf9;
      padding-left: 14rem;
      margin-right: 14rem;
      font-size: 18rem;
      color: #252927;
    }
    .tips-payAmount {
      font-size: 20rem;
      color: #252927;
      line-height: 28rem;
      span {
        font-size: 28rem;
        color: #252927;
        line-height: 40rem;
        margin-left: 8rem;
      }
    }
    .dialog-footer {
      width: 100%;
      margin-top: 56rem;
      div {
        width: 158rem;
        height: 44rem;
        background: linear-gradient(225deg, #4facfe 0%, #28dae3 100%);
        border-radius: 22rem;
        text-align: center;
        line-height: 44rem;
        color: #fff;
        img {
          position: relative;
          bottom: 6rem;
        }
      }
    }
    .service {
      font-size: 16rem;
      color: #7c7c7c;
      margin-top: 24rem;
    }
  }
}
::v-deep .el-dialog__close {
  font-size: 24rem;
}
::v-deep .payType {
  height: 100%;
  .el-radio__input {
    display: none;
  }

  .icon {
    font-size: 20rem;
    margin-right: 5rem;
    position: relative;
    top: 2rem;
  }
  img {
    width: 24rem;
    margin-right: 10rem;
  }
  span {
    font-size: 16rem;
    color: #252927;
  }
}
::v-deep .el-radio.is-bordered {
  height: 40rem;
  text-align: center;
  line-height: 40rem;
  border-radius: 12rem;
  border: 1px solid #e0e0e0;
  padding: 0;
  transition: all 0.3s;
  &:hover {
    border: 1rem solid #32c3f6;
  }
}

::v-deep .el-radio.is-disabled {
  height: 40rem;
  text-align: center;
  line-height: 40rem;
  border-radius: 12rem;
  background-color: #eeeeee;
  border: 1px solid #e0e0e0;
  padding: 0;
  transition: all 0.3s;
  &:hover {
    border: 1px solid #e0e0e0;
  }
}

::v-deep .el-radio__label {
  padding-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .el-radio:nth-child(1) {
  .icon {
    color: #09bb07;
  }
  .el-radio__input.is-checked + .el-radio__label {
    span {
      color: #606266;
    }
  }
}
::v-deep .el-radio:nth-child(2),
::v-deep .el-radio:nth-child(3) {
  .icon {
    color: #02a9f1;
  }
  .el-radio__input.is-checked + .el-radio__label {
    span {
      color: #606266;
    }
  }
}
::v-deep .el-radio.is-checked {
  background: linear-gradient(
    225deg,
    rgba(79, 172, 254, 0.1) 0%,
    rgba(40, 218, 227, 0.1) 100%
  );
  border: 1rem solid #32c3f6;
}
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  padding: 60rem 70rem 32rem;
}
::v-deep .el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 20rem;
}
::v-deep .el-radio {
  margin-right: 0;
}
::v-deep .el-radio {
  width: 138rem;
}
::v-deep .el-dialog {
  background: rgba(255, 255, 255, 0.95);
}
</style>
