import { render, staticRenderFns } from "./productPrice.vue?vue&type=template&id=305ce334&scoped=true"
import script from "./productPrice.vue?vue&type=script&lang=js"
export * from "./productPrice.vue?vue&type=script&lang=js"
import style0 from "./productPrice.vue?vue&type=style&index=0&id=305ce334&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "305ce334",
  null
  
)

export default component.exports